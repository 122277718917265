<template>
  <div class="page_content page_content_order">
    <div class="content_header d-flex align-items-center">
      <span>會員訂購</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="search_section">
          <div class="form-group">
            <select
              id="system"
              v-model="search.system"
              v-validate="'required'"
              name="系統"
              class="form-control search_brand"
              :class="{'is-invalid': errors.has('search.系統')}"
              data-vv-scope="search"
              @change="get_main_category($event.target.value)"
            >
              <option
                value
                disabled
                selected
                hidden
              >
                系統
              </option>
              <option
                v-for="item in system_data"
                :key="item.systemCode"
                :value="item.systemCode"
              >
                {{ item.systemName }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <select
              id="main_category"
              v-model="search.main_category"
              v-validate="'required'"
              name="主分類"
              class="form-control"
              :class="{'is-invalid': errors.has('search.主分類')}"
              data-vv-scope="search"
              @change="get_sub_category()"
            >
              <option
                value
                disabled
                selected
                hidden
              >
                主分類品項
              </option>
              <option
                v-for="item in category_data"
                :key="item.categoryCode"
                :value="item.categoryCode"
              >
                {{ item.categoryName }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <select
              id="sub_category"
              v-model="search.sub_category"
              v-validate="'required'"
              name="次分類"
              class="form-control"
              :class="{'is-invalid': errors.has('search.次分類')}"
              data-vv-scope="search"
            >
              <option
                value
                disabled
                selected
                hidden
              >
                次分類品項
              </option>
              <option
                v-for="item in subcategory_data"
                :key="item.categorySubCode"
                :value="item.categorySubCode"
              >
                {{ item.categorySubName }}
              </option>
            </select>
          </div>
          <a
            href="#"
            class="search_btn d-flex align-items-center justify-content-center"
            @click.prevent="get_product_list()"
          >
            <span>搜尋</span>
          </a>
        </div>
        <ul
          class="nav nav-tabs"
        >
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': !cart_mode }"
              @click.prevent="cart_mode = false"
            >商品列表</a>
          </li>
          <li
            v-if="cart.items.length !== 0"
            class="nav-item"
          >
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': cart_mode }"
              @click.prevent="cart_mode = true"
            >購物車</a>
          </li>
        </ul>
        <div
          v-if="!cart_mode"
          class="row"
        >
          <div
            v-for="product in product_list"
            :key="product.prodSerial"
            class="col-12 col-md-6 col-xl-3 mb-3"
          >
            <div class="product_card d-flex flex-column">
              <span class="card_title">{{ product.prodName + product.standard }}</span>
              <div class="card_body d-flex flex-fill">
                <div class="d-flex flex-column flex-fill">
                  <div v-if="product.price === product.priceDiscount">
                    售價：{{ product.price | currency }}
                  </div>
                  <div v-else>
                    售價：{{ product.price | currency }}
                    <span class="text-danger"> 優惠價：{{ product.priceDiscount | currency }}</span>
                  </div>
                  <div
                    v-if="product.optionOutOfStock"
                    class="text-danger"
                  >
                    缺貨中
                  </div>
                  <div v-else>
                    庫存 <span class="text-danger">{{ product.quantityStorage | currency }}</span>
                  </div>
                </div>
                <div class="product_image">
                  <img
                    :src="product.image430x430"
                    alt=""
                    class="img-fluid"
                  >
                </div>
              </div>
              <a
                v-if="product.optionOutOfStock"
                href="#"
                class="btn btn_default"
                @click.prevent=""
              >缺貨中</a>
              <a
                v-else
                href="#"
                class="btn btn_primary"
                @click.prevent="addto_cart(product, 1)"
              >購買</a>
            </div>
          </div>
        </div>
        <div
          v-if="cart_mode && cart.items.length !== 0"
          class="row"
        >
          <div class="col-12">
            <div class="table-responsive">
              <table
                v-show="order_step === 1"
                class="table table-borderless"
              >
                <thead class="">
                  <tr>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      商品圖片
                    </th>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      商品代碼
                    </th>
                    <th
                      scope="col"
                      class="text-color_primary"
                    >
                      名稱
                    </th>
                    <th
                      scope="col"
                      class="text-center text-color_primary"
                    >
                      價格
                    </th>
                    <th
                      scope="col"
                      class="text-center text-color_primary"
                    >
                      數量
                    </th>
                    <th
                      scope="col"
                      class="text-right text-color_primary"
                    >
                      小計
                    </th>
                    <th
                      scope="col"
                      class="text-center text-color_primary"
                    >
                      編輯
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="cart.items.length !== 0">
                    <tr
                      v-for="product in cart.items"
                      :key="product.prodSerial"
                    >
                      <td>
                        <div class="d-flex align-items-center justify-content-center">
                          <img
                            :src="product.image430x430"
                            alt=""
                            class="img-fluid"
                          >
                        </div>
                      </td>
                      <td>
                        {{ product.prodSerial }}
                      </td>
                      <td>
                        {{ product.prodName + product.standard }}
                      </td>
                      <td>
                        <div class="d-flex flex-column align-items-center">
                          <div
                            v-if="product.price !== product.priceOriginal"
                            class="text-danger"
                          >
                            {{ product.price | currency }}
                          </div>
                          <del v-if="product.price !== product.priceOriginal">{{ product.priceOriginal | currency }}</del>
                          <span v-if="product.price === product.priceOriginal">{{ product.price | currency }}</span>
                        </div>
                      </td>
                      <td class="text-center">
                        <select @change="toggle_select_amount($event, product.prodSerial), update_cart(product, $event)">
                          <option
                            disabled
                            selected
                            hidden
                            :value="product.quantity"
                          >
                            {{ product.quantity | currency }}
                          </option>
                          <template v-if="Math.floor(product.quantityMax) > 999">
                            <option
                              v-for="(item, index) in 999"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </template>
                          <template v-else>
                            <option
                              v-for="(item, index) in Math.floor(product.quantityMax)"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </template>
                        </select>
                      </td>
                      <td class="text-right">
                        {{ product.amount | currency }}
                      </td>
                      <td>
                        <a
                          href="#"
                          class="d-flex align-items-center justify-content-center text-danger"
                          @click.prevent="delete_cart(product)"
                        >
                          <i class="icon-trash" />
                        </a>
                      </td>
                    </tr>
                  </template>
                  <template v-if="cart.transfee.length !== 0">
                    <tr
                      v-for="transfee in cart.transfee"
                      :key="transfee.prodSerial"
                    >
                      <td>
                        <div class="d-flex align-items-center justify-content-center">
                          <img
                            :src="transfee.image430x430"
                            alt=""
                            class="img-fluid"
                          >
                        </div>
                      </td>
                      <td>
                        {{ transfee.prodSerial }}
                      </td>
                      <td>
                        {{ transfee.prodName }}
                      </td>
                      <td>
                        <div class="d-flex flex-column align-items-center">
                          <div
                            v-if="transfee.price !== transfee.priceOriginal"
                            class="text-danger"
                          >
                            {{ transfee.price }}
                          </div>
                          <del v-if="transfee.price !== transfee.priceOriginal">{{ transfee.priceOriginal }}</del>
                          <span v-if="transfee.price === transfee.priceOriginal">{{ transfee.price }}</span>
                        </div>
                      </td>
                      <td class="text-center">
                        {{ transfee.quantity }}
                      </td>
                      <td class="text-right">
                        {{ transfee.amount }}
                      </td>
                      <td />
                    </tr>
                  </template>
                  <template v-if="cart.coupon.length !== 0">
                    <tr
                      v-for="coupon in cart.coupon"
                      :key="coupon.prodSerial"
                    >
                      <td>
                        <div class="d-flex align-items-center justify-content-center">
                          <img
                            :src="coupon.image430x430"
                            alt=""
                            class="img-fluid"
                          >
                        </div>
                      </td>
                      <td>
                        {{ coupon.prodSerial }}
                      </td>
                      <td>
                        {{ coupon.prodName }}
                      </td>
                      <td>
                        <div class="d-flex flex-column align-items-center">
                          <span class="text-danger">{{ coupon.price | currency }}</span>
                        </div>
                      </td>
                      <td class="text-center">
                        {{ coupon.quantity }}
                      </td>
                      <td class="text-right">
                        {{ coupon.amount | currency }}
                      </td>
                      <td />
                    </tr>
                  </template>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3">
                      <div class="form-group d-flex align-items-center">
                        <label for="arriveDate">到貨日期</label>
                        <select
                          id="arriveDate"
                          v-model="cart_info.arriveDate"
                          v-validate="'required'"
                          name="到貨日期"
                          class="form-control form_input"
                          :class="{'is-invalid': errors.has('order.到貨日期')}"
                          data-vv-scope="order"
                          @change="set_arrive_time(cart_info.arriveDate)"
                        >
                          <option
                            value
                            disabled
                            selected
                            hidden
                          >
                            到貨日期
                          </option>
                          <option
                            v-for="item in cart.condition.arriveDate"
                            :key="item.date"
                            :value="item.date"
                          >
                            {{ item.date + item.weekly }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group d-flex align-items-center mb-2">
                        <label for="serviceDiscount">客服優惠:</label>
                        <input
                          id="serviceDiscount"
                          v-model="service_discount"
                          type="text"
                          name="設定優惠"
                          class="form-control form_input"
                        >
                        <a
                          href="#"
                          class="btn btn_primary px-2 rounded-0"
                          @click.prevent="add_discount()"
                        >設定優惠</a>
                      </div>
                      <div class="form-group d-flex align-items-baseline">
                        <label>免運設定</label>
                        <div class="form-check form-check-inline">
                          <input
                            id="noTransFee"
                            v-model="notransfee"
                            class="form-check-input"
                            type="checkbox"
                            name="免運設定"
                            :true-value="true"
                            :false-value="false"
                            @change="set_transfee(notransfee)"
                          >
                          <label
                            class="form-check-label"
                            for="noTransFee"
                          >是</label>
                        </div>
                      </div>
                      <div class="form-group d-flex align-items-baseline">
                        <label>排除所有優惠活動</label>
                        <div class="form-check form-check-inline">
                          <input
                            id="Option_Disable_Event"
                            v-model="option_disable_event"
                            class="form-check-input"
                            type="checkbox"
                            name="排除所有優惠活動"
                            :true-value="true"
                            :false-value="false"
                            @change="set_option( 'DisableEvent', option_disable_event)"
                          >
                          <label
                            class="form-check-label"
                            for="Option_Disable_Event"
                          >是(商品會全部算原價，排除累積優惠、VIP身分優惠也不會送贈品)</label>
                        </div>
                      </div>
                      <div class="form-group d-flex align-items-baseline">
                        <label>排除贈送回饋金</label>
                        <div class="form-check form-check-inline">
                          <input
                            id="Option_Disable_Rebate"
                            v-model="option_disable_rebate"
                            class="form-check-input"
                            type="checkbox"
                            name="排除贈送回饋金"
                            :true-value="true"
                            :false-value="false"
                            @change="set_option( 'DisableRebate', option_disable_rebate)"
                          >
                          <label
                            class="form-check-label"
                            for="Option_Disable_Rebate"
                          >是(活動回饋金在優惠活動排除，這邊排除的是網站固有回饋金)</label>
                        </div>
                      </div>
                    </td>
                    <td
                      colspan="3"
                      class="text-right"
                    >
                      總計
                    </td>
                    <td class="text-center text-danger">
                      NT$ {{ cart.pricing.amount | currency }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div v-show="order_step === 2">
              <div class="order_section">
                <p class="section_title text-color_primary">
                  訂購人
                </p>
                <div class="form-group d-flex align-items-center">
                  <label for="purchaser_name">姓名</label>
                  <input
                    id="purchaser_name"
                    v-model="pur_profile.fullname"
                    v-validate="'required'"
                    type="text"
                    name="訂購人姓名"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('order.訂購人姓名')}"
                    data-vv-scope="order"
                  >
                </div>
                <div class="form-group d-flex align-items-center">
                  <label for="purchaser_celnum">行動電話</label>
                  <input
                    id="purchaser_celnum"
                    v-model="pur_profile.celnum"
                    v-validate="'required'"
                    type="tel"
                    name="訂購人行動電話"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('order.訂購人行動電話')}"
                    data-vv-scope="order"
                  >
                </div>
                <div class="form-row">
                  <div class="col-12 col-md-6">
                    <div class="form-group d-flex align-items-center">
                      <label for="purchaser_telnum">市話</label>
                      <input
                        id="purchaser_telnum"
                        v-model="pur_profile.telnum"
                        type="tel"
                        name="訂購人市話"
                        class="form-control form_input"
                      >
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group d-flex align-items-center">
                      <label for="purchaser_telnumExt">分機</label>
                      <input
                        id="purchaser_telnumExt"
                        v-model="pur_profile.telnumExt"
                        type="tel"
                        name="訂購人分機"
                        class="form-control form_input"
                      >
                    </div>
                  </div>
                </div>
                <div class="form-group d-flex align-items-center">
                  <label for="purchaser_email">Email</label>
                  <input
                    id="purchaser_email"
                    v-model="pur_profile.email"
                    type="email"
                    name="訂購人Email"
                    class="form-control form_input"
                  >
                </div>
                <div class="form-group d-flex align-items-baseline">
                  <label for="">地址</label>
                  <div class="d-flex flex-column w-100">
                    <div class="d-flex">
                      <select
                        id="purchaser_county"
                        v-model="pur_profile.countyName"
                        v-validate="'required'"
                        name="訂購縣市"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('order.訂購縣市')}"
                        data-vv-scope="order"
                        @change="filter_district('pur_profile')"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          縣市
                        </option>
                        <option
                          v-for="item in option.address"
                          :key="item.addrCountyCode"
                          :value="item.addrCountyName"
                        >
                          {{ item.addrCountyName }}
                        </option>
                      </select>
                      <select
                        id="purchaser_city"
                        v-model="pur_profile.cityName"
                        v-validate="'required'"
                        name="訂購行政區"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('order.訂購行政區')}"
                        data-vv-scope="order"
                        @change="get_city_code('pur_profile')"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          行政區
                        </option>
                        <option
                          v-for="item in pur_profile_district"
                          :key="item.addrCityCode"
                          :value="item.addrCityName"
                        >
                          {{ item.addrCityName }}
                        </option>
                      </select>
                    </div>
                    <input
                      id="purchaser_address"
                      v-model="pur_profile.detail"
                      v-validate="'required'"
                      type="text"
                      name="訂購地址"
                      class="form-control form_input flex-fill mt-2"
                      :class="{'is-invalid': errors.has('order.訂購地址')}"
                      placeholder="請輸入地址"
                      data-vv-scope="order"
                    >
                  </div>
                </div>
              </div>
              <div class="order_section">
                <p class="section_title text-color_primary">
                  收件人
                </p>
                <div class="form-group d-flex align-items-center">
                  <label for="recipient_name">姓名</label>
                  <input
                    id="recipient_name"
                    v-model="rec_profile.fullname"
                    v-validate="'required'"
                    type="text"
                    name="收件人姓名"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('order.收件人姓名')}"
                    data-vv-scope="order"
                  >
                </div>
                <div class="form-group d-flex align-items-center">
                  <label for="recipient_celnum">行動電話</label>
                  <input
                    id="recipient_celnum"
                    v-model="rec_profile.celnum"
                    v-validate="'required'"
                    type="tel"
                    name="收件人行動電話"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('order.收件人行動電話')}"
                    data-vv-scope="order"
                  >
                </div>
                <div class="form-row">
                  <div class="col-12 col-md-6">
                    <div class="form-group d-flex align-items-center">
                      <label for="recipient_telnum">市話</label>
                      <input
                        id="recipient_telnum"
                        v-model="rec_profile.telnum"
                        type="tel"
                        name="收件人市話"
                        class="form-control form_input"
                      >
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group d-flex align-items-center">
                      <label for="recipient_telnumExt">分機</label>
                      <input
                        id="recipient_telnumExt"
                        v-model="rec_profile.telnumExt"
                        type="tel"
                        name="收件人分機"
                        class="form-control form_input"
                      >
                    </div>
                  </div>
                </div>
                <div class="form-group d-flex align-items-baseline">
                  <label for="">地址</label>
                  <div class="d-flex flex-column w-100">
                    <div class="d-flex">
                      <select
                        id="recipient_county"
                        v-model="rec_profile.countyName"
                        v-validate="'required'"
                        name="收件縣市"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('order.收件縣市')}"
                        data-vv-scope="order"
                        @change="filter_district('rec_profile')"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          縣市
                        </option>
                        <option
                          v-for="item in option.address"
                          :key="item.addrCountyCode"
                          :value="item.addrCountyName"
                        >
                          {{ item.addrCountyName }}
                        </option>
                      </select>
                      <select
                        id="recipient_city"
                        v-model="rec_profile.cityName"
                        v-validate="'required'"
                        name="收件行政區"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('order.收件行政區')}"
                        data-vv-scope="order"
                        @change="get_city_code('rec_profile')"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          行政區
                        </option>
                        <option
                          v-for="item in rec_profile_district"
                          :key="item.addrCityCode"
                          :value="item.addrCityName"
                        >
                          {{ item.addrCityName }}
                        </option>
                      </select>
                    </div>
                    <input
                      id="recipient_address"
                      v-model="rec_profile.detail"
                      v-validate="'required'"
                      type="text"
                      name="收件地址"
                      class="form-control form_input flex-fill mt-2"
                      :class="{'is-invalid': errors.has('order.收件地址')}"
                      placeholder="請輸入地址"
                      data-vv-scope="order"
                    >
                  </div>
                </div>
              </div>
              <div class="order_section">
                <div class="form-group d-flex align-items-center">
                  <label for="delivery">運送方式</label>
                  <select
                    id="delivery"
                    v-model="cart_info.delivery"
                    v-validate="'required'"
                    name="運送方式"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('order.運送方式')}"
                    data-vv-scope="order"
                  >
                    <option
                      value
                      disabled
                      selected
                      hidden
                    >
                      運送方式
                    </option>
                    <option
                      v-for="item in option.delivery"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="form-group d-flex align-items-center">
                  <label for="arriveTime">到貨時間</label>
                  <select
                    id="arriveTime"
                    v-model="cart_info.arriveTime"
                    v-validate="'required'"
                    name="到貨時間"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('order.到貨時間')}"
                    data-vv-scope="order"
                  >
                    <option
                      value
                      disabled
                      selected
                      hidden
                    >
                      到貨時間
                    </option>
                    <option
                      v-for="item in cart.condition.arriveTime"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="form-group d-flex align-items-center">
                  <label for="paymentCode">付款方式</label>
                  <select
                    id="paymentCode"
                    v-model="cart_info.paymentCode"
                    v-validate="'required'"
                    name="付款方式"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('order.付款方式')}"
                    data-vv-scope="order"
                  >
                    <option
                      value
                      disabled
                      selected
                      hidden
                    >
                      付款方式
                    </option>
                    <option
                      v-for="item in cart.condition.payment"
                      :key="item.paymentCode"
                      :value="item.paymentCode"
                    >
                      {{ item.paymentName }}
                    </option>
                  </select>
                </div>
                <div class="form-group d-flex align-items-baseline">
                  <label>溫層分別寄送</label>
                  <div class="form-check form-check-inline">
                    <input
                      id="optionSplitup"
                      v-model="cart_info.optionSplitup"
                      class="form-check-input"
                      type="checkbox"
                      name="溫層分別寄送"
                      :true-value="true"
                      :false-value="false"
                    >
                    <label
                      class="form-check-label"
                      for="optionSplitup"
                    >是</label>
                  </div>
                </div>
                <div class="form-group d-flex align-items-baseline">
                  <label>購物金折抵</label>
                  <div class="form-check form-check-inline">
                    <input
                      id="optionPointDiscount"
                      v-model="cart_info.optionPointDiscount"
                      class="form-check-input"
                      type="checkbox"
                      name="購物金折抵"
                      :true-value="true"
                      :false-value="false"
                    >
                    <label
                      class="form-check-label"
                      for="optionPointDiscount"
                    >是</label>
                  </div>
                  <div class="d-flex mr-2">
                    <span class="mr-2">帳戶金額</span>
                    <span class="text-color_primary">{{ cart.pricing.accountPoint }}</span>
                  </div>
                  <div class="d-flex">
                    <span class="mr-2">最大可折抵金額</span>
                    <span class="text-color_primary">{{ cart.pricing.accountPointDiscountMax }}</span>
                  </div>
                </div>
                <div class="form-group d-flex align-items-center">
                  <label for="receipt_type">發票類型</label>
                  <select
                    id="receipt_type"
                    v-model="cart_info.receipt_type"
                    v-validate="'required'"
                    name="發票類型"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('order.發票類型')}"
                    data-vv-scope="order"
                  >
                    <option
                      value
                      disabled
                      selected
                      hidden
                    >
                      發票類型
                    </option>
                    <option
                      v-for="item in option.receiptType"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="form-group d-flex align-items-center">
                  <label for="receipt_ubn">統編</label>
                  <input
                    id="receipt_ubn"
                    v-model="cart_info.receipt_ubn"
                    type="text"
                    name="統編"
                    class="form-control form_input"
                  >
                </div>
                <div class="form-group d-flex align-items-center">
                  <label for="receipt_title">抬頭</label>
                  <input
                    id="receipt_title"
                    v-model="cart_info.receipt_title"
                    type="text"
                    name="抬頭"
                    class="form-control form_input"
                  >
                </div>
                <div class="form-group d-flex align-items-center">
                  <label for="vehicleMobile">手機載具</label>
                  <input
                    id="vehicleMobile"
                    v-model="cart_info.vehicleMobile"
                    type="text"
                    name="手機載具"
                    class="form-control form_input"
                  >
                </div>
                <div class="form-group d-flex align-items-baseline">
                  <label>備註</label>
                  <textarea
                    id=""
                    v-model="cart_info.memo"
                    name="備註"
                    class="form-control form_input"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <a
                v-show="order_step === 2"
                href="#"
                class="btn btn_primary px-2"
                @click.prevent="order_step = 1"
              >上一步</a>
              <a
                v-show="order_step === 2"
                href="#"
                class="btn btn_primary ml-2 px-2"
                @click.prevent="creat_order()"
              >送出訂單</a>
              <a
                v-show="order_step === 1"
                href="#"
                class="btn btn_primary px-2"
                @click.prevent="order_step = 2"
              >確認資料</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_CART_CATEGORY,
  API_GET_CART_PRODUCT_LIST,
  API_ADD_DISCOUNT,
  API_SET_NOTRANSFEE,
  API_SET_OPTION,
  API_CANCEL_NOTRANSFEE,
  API_SET_FUGUI_TIME,
  API_POST_ORDER,
} from '@/plugins/api';

export default {
  data() {
    return {
      search: {
        /* 搜尋資料 */
        system: '',
        main_category: '',
        sub_category: '',
      },
      cart_mode: false,
      category_data: [] /* 主次分類資料 */,
      subcategory_data: [] /* 次分類列表 */,
      product_list: [] /* 商品列表 */,
      product_list_with_detail: [] /* 商品列表(含細節) */,
      product_detail: {},
      now_product_id: '',
      service_discount: '', /* 客服優惠 */
      notransfee: false, /* 免運設定 */
      option_disable_event: false, /* 是否關閉參加所有活動 */
      option_disable_rebate: false, /* 是否關閉回饋金 */
      order_step: 1, /* 下單步驟 */
      pur_profile_district: [],
      rec_profile_district: [],
      arrive_date: [], /* 福貴糕到貨日期 */
      cart_info: {
        delivery: '',
        arriveDate: '',
        arriveTime: '',
        paymentCode: '',
        optionSplitup: false,
        optionPointDiscount: false,
        memo: '',
        receipt_type: '',
        receipt_ubn: '',
        receipt_title: '',
        vehicleMobile: '',
      },
    };
  },
  computed: {
    ...mapGetters('system', ['system', 'option']),
    ...mapGetters('cart', ['cart']),
    system_data: {
      get() {
        const vm = this;
        const data = vm.system.slice();
        return data;
      },
    },
    pur_profile: {
      get() {
        const vm = this;
        const data = { ...vm.$store.getters['cart/pur_profile'] };
        return data;
      },
    },
    rec_profile: {
      get() {
        const vm = this;
        const data = { ...vm.$store.getters['cart/rec_profile'] };
        return data;
      },
    },
  },
  async mounted() {
    const vm = this;
    await vm.get_system();
    await vm.get_system_option();
    await vm.get_cart();
    await vm.filter_district();
  },
  methods: {
    ...mapActions('system', ['get_system', 'get_system_option']),
    ...mapActions('cart', ['get_cart']),
    /* 取得主次分類列表 */
    async get_main_category(value) {
      const vm = this;
      const response = await API_GET_CART_CATEGORY(value);
      const res = response.data;
      // console.log('取得主次分類列表 成功', res);
      if (res.code === '0000') {
        vm.category_data = res.data;
      }
    },
    /* 篩選次分類列表 */
    async get_sub_category() {
      const vm = this;
      vm.category_data.forEach((item) => {
        if (item.categoryCode === vm.search.main_category) {
          vm.subcategory_data = item.items;
        }
      });
    },
    /* 取得商品列表 */
    async get_product_list() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('search');
      if (validateResult) {
        const response = await API_GET_CART_PRODUCT_LIST(vm.search.sub_category);
        const res = response.data;
        // console.log('取得商品列表 成功', res);
        if (res.code === '0000') {
          vm.product_list = res.data;
        }
      }
    },
    /* 加入購物車 */
    async addto_cart(prod, qty) {
      /* 檢查購物車此商品購買數量是否已達最大購買量 */
      const vm = this;
      const haveCart = vm.cart.items.length !== 0;
      const product = {
        prodSerial: prod.prodSerial,
        fromProdSerial: '',
        type: prod.type,
        mode: '購買品',
        isReturnCart: true,
        quantity: qty,
        deliveryDays: 0,
        deliveryTimes: 1,
      };
      if (haveCart) {
        const cartData = vm.cart.items;
        const cartSameProduct = cartData.find((item) => item.prodSerial === product.prodSerial);
        if (cartSameProduct) {
          const isMaxAmount = cartSameProduct.quantity === Math.floor(cartSameProduct.quantityMax);
          if (isMaxAmount) {
            alert('已達最大購買數量');
            return;
          }
          product.quantity = cartSameProduct.quantity + qty;
          await vm.$store.dispatch('cart/update_cart', { product, qty });
          vm.$swal({
            icon: 'success',
            title: '已加入購物車!',
            confirmButtonText: '關閉視窗',
          });
        } else {
          await vm.$store.dispatch('cart/addto_cart', { product, qty });
          vm.$swal({
            icon: 'success',
            title: '已加入購物車!',
            confirmButtonText: '關閉視窗',
          });
        }
      } else {
        await vm.$store.dispatch('cart/addto_cart', { product, qty });
        vm.$swal({
          icon: 'success',
          title: '已加入購物車!',
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 收合數量選單 */
    toggle_select_amount(e, id) {
      const vm = this;
      if (vm.now_product_id === id) {
        vm.now_product_id = '';
        return;
      }
      vm.now_product_id = id;
    },
    /* 修改數量 更新購物車 */
    async update_cart(prod, e) {
      const vm = this;
      const qty = Number($(e.currentTarget).val()); /* 選取到的數量 */
      const product = {
        prodSerial: prod.prodSerial,
        fromProdSerial: '',
        type: prod.type,
        mode: '購買品',
        isReturnCart: true,
        quantity: qty,
        deliveryDays: 0,
        deliveryTimes: 1,
      };
      vm.$store.dispatch('cart/update_cart', { product });
    },
    /* 刪除購物車 */
    async delete_cart(product) {
      const vm = this;
      await vm.$store.dispatch('cart/delete_cart', product);
      await vm.get_cart();
      if (vm.cart.items.length === 0) {
        vm.cart_mode = false;
      }
    },
    /* 加入客服優惠 */
    async add_discount() {
      const vm = this;
      const response = await API_ADD_DISCOUNT(vm.service_discount);
      const res = response.data;
      if (res.code === '0000') {
        // console.log('加入客服優惠 成功', response);
        await vm.get_cart();
      }
    },
    /* 篩選行政區 */
    async filter_district(target) {
      const vm = this;
      switch (target) {
        case 'pur_profile':
          if (vm.option.address.length !== 0) {
            vm.option.address.forEach((item) => {
              if (item.addrCountyName === vm.pur_profile.countyName) {
                vm.pur_profile_district = item.cities;
              }
            });
          }
          break;
        case 'rec_profile':
          if (vm.option.address.length !== 0) {
            vm.option.address.forEach((item) => {
              if (item.addrCountyName === vm.rec_profile.countyName) {
                vm.rec_profile_district = item.cities;
              }
            });
          }
          break;
        default:
          vm.filter_district('pur_profile');
          vm.filter_district('rec_profile');
          break;
      }
    },
    /* 取得郵遞區號 */
    get_city_code(target) {
      const vm = this;
      switch (target) {
        case 'pur_profile':
          if (vm.pur_profile_district.length !== 0) {
            vm.pur_profile_district.forEach((item) => {
              if (item.addrCityName === vm.pur_profile.cityName) {
                vm.pur_profile.zip = item.addrZip;
                vm.pur_profile.cityCode = item.addrCityCode;
              }
            });
          }
          break;
        case 'rec_profile':
          if (vm.rec_profile_district.length !== 0) {
            vm.rec_profile_district.forEach((item) => {
              if (item.addrCityName === vm.rec_profile.cityName) {
                vm.rec_profile.zip = item.addrZip;
                vm.rec_profile.cityCode = item.addrCityCode;
              }
            });
          }
          break;
        default:
          break;
      }
    },
    async set_arrive_time(arriveDate) {
      const vm = this;
      const isFugui = vm.cart.items.some((item) => item.prodSerial === 'A0801-0400000T' || item.prodSerial === 'A0801-0280002T' || item.prodSerial === 'A0801-0670000T' || item.prodSerial === 'A0801-0660000T' || item.prodSerial === 'A0801-0650000T');
      if (isFugui) {
        const response = await API_SET_FUGUI_TIME(arriveDate);
        const res = response.data;
        if (res.code === '0000') {
          await vm.get_cart();
          // console.log('設定到貨日期 成功');
        }
      }
    },
    /* 免運設定 */
    async set_transfee(status) {
      const vm = this;
      if (status) {
        const response = await API_SET_NOTRANSFEE();
        const res = response.data;
        if (res.code === '0000') {
          // console.log('免運設定 成功', response);
          await vm.get_cart();
        }
      } else {
        const response = await API_CANCEL_NOTRANSFEE();
        const res = response.data;
        if (res.code === '0000') {
          // console.log('取消免運設定 成功', response);
          await vm.get_cart();
        }
      }
    },
    /* 購物車選項設定 */
    async set_option(mode, status) {
      const vm = this;
      const data = {
        IsReturnCart: true,
        OptionMode: mode,
        OptionSet: status,
      };
      const response = await API_SET_OPTION(data);
      const res = response.data;
      if (res.code !== '0000') {
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: res.text,
          confirmButtonText: '關閉視窗',
        });
      }
      await vm.get_cart();
    },
    /* 送出訂單 */
    async creat_order() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('order');
      if (validateResult) {
        // 檢查發票類型是否為email必填項目
        if ((vm.cart_info.receipt_type === '電子發票' || vm.cart_info.receipt_type === '手機載具' || vm.cart_info.receipt_type === '公司用') && vm.pur_profile.email === '') {
          vm.$swal({
            icon: 'error',
            title: '錯誤!',
            html: '<p class="text-left">依據發票類型，email欄位必填</p>',
            confirmButtonText: '關閉視窗',
          });
          return;
        }
        const data = {
          info: {
            delivery: vm.cart_info.delivery,
            arriveDate: vm.cart_info.arriveDate,
            arriveTime: vm.cart_info.arriveTime,
            paymentCode: vm.cart_info.paymentCode,
            optionSplitup: vm.cart_info.optionSplitup,
            optionPointDiscount: vm.cart_info.optionPointDiscount,
            memo: vm.cart_info.memo,
          },
          pur: vm.pur_profile,
          rec: vm.rec_profile,
          receipt: {
            type: vm.cart_info.receipt_type,
            ubn: vm.cart_info.receipt_ubn,
            title: vm.cart_info.receipt_title,
            vehicleMobile: vm.cart_info.vehicleMobile,
          },
        };
        const response = await API_POST_ORDER(data);
        const res = response.data;
        // console.log('送出訂單 成功', response);
        if (res.code === '0000') {
          vm.$router.push('/dashboard/member');
          vm.$swal({
            icon: 'success',
            title: '成功',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
  },
};
</script>
